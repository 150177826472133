//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PostExcerpt from '~/components/posts/PostExcerpt.vue'
import TagList from '~/lib/TagList'
import CatList from '~/lib/CatList'

export default {
	props: {
		'needfixedtag': {
			type: Boolean
		},
		'filteredbycurrenttag': {
			type: Boolean
		},
		'excludecurrenttag': {
			type: Boolean
		}
	},
    data() {
      return {
        posts: this.$store.state.posts,
        current_post: this.$store.state.post,
        tags: this.$store.state.tags,
        cats: this.$store.state.cats,
        filteredByCat: this.$store.state.filteredByCat,
		visibleTagID:[],
        activeTag:[],
		visibleCatID:[],
		activeCat:[],
		currentPostVisibleID: [],
		load_more_project_loading:false,
		load_more_project_btn:false,
		btn_load_more_project: null,
		fixed_tag:false,
		tag_warper:null,
		bounding_tag:0,
		height_tag:0,
      }
    },
	// watch: {
	// 	'$store.state.filteredByCat': function() {
	// 		console.log('bim');
	// 		this.filtercat(this.$store.state.filteredByCat)
	// 	}
	// },
   	components: {
    	PostExcerpt,
  	},
  	mounted(){
		console.log('mounted------------------');
		console.log(this.$store.state.filteredByCat);
		console.log(this.filteredByCat);
  		this.fillVisibleId()
		this.load_more_project_btn = this.$store.state.load_more_project_btn;
		this.$nextTick(() => { 
			this.btn_load_more_project = this.$el.querySelector('.load_more_project')
		})
		this.tag_warper = this.$el.querySelector('.window_scroll');
		this.bounding_tag = this.tag_warper.getBoundingClientRect();
  	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScrollLoadMore);
		if(this.needfixedtag && (this.$mq!='sm' && this.$mq!='md')){
			window.addEventListener('scroll', this.handleScrollTag);
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScrollLoadMore);
		if(this.needfixedtag){
			window.addEventListener('scroll', this.handleScrollTag);
		}
	},
  	methods: {
		fillVisibleId () {
			console.log('################-----------------'+this.$store.state.filteredByCat);
			console.log('################-----------------'+this.filteredByCat);
			this.posts.forEach((post) => {
				if(this.filteredbycurrenttag){/////---------------------------- if we filter post list by related tag 
					this.current_post.tags_infos.id.forEach((tag_id) => {/////- for each tag id from the current post 
						if(post.tags_infos.id.includes(tag_id)){/////////------ if this post has one tag indentical to current post
							this.addToVisible(post)///////////////////////----- we had the post to the list of visible post
						}
					})
				}
				if(this.filteredByCat){/////---------------------------- if we filter post list by cat 
					post.cats_infos.slug.forEach((slug) => {/////- for each cat slug from the post we're looking at 
						console.log(slug);
						if(this.filteredByCat==slug){////////////- if it's the current cat 
							this.addToVisible(post)///////////////////////----- we had the post to the list of visible post
						}
					})
				}
				else{/////////////////////////////---------------------------- else we add the post to the list of visible post
					this.addToVisible(post)
				}
			})
		},
		addToVisible (post) {
			this.currentPostVisibleID.push(post.id)///////--------------------- we add the post to the list of visible post
			post.tags_infos.id.forEach((tag_id) => {///////-------------------- for each tag of this newly added post
				if(!this.visibleTagID.includes(tag_id)){//////----------------- if not yet in the visible tag list 
					this.visibleTagID.push(tag_id);//////---------------------- we add the tag to the  visible tag list 
				}
			})
			
		},
		isInViewport: function(elem){
			var bounding = elem.getBoundingClientRect();
			return (
				bounding.top >= 0 &&
				bounding.left >= 0 &&
				bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		},
		handleScrollLoadMore () {
			if(!this.btn_load_more_project){
				return;
			}
			if (this.isInViewport(this.btn_load_more_project) && this.btn_load_more_project){
				console.log('load more from scroll');
				this.load_more_project();
				window.removeEventListener('scroll', this.handleScrollLoadMore);
			}
		},
		handleScrollTag () {
			// Your scroll handling here
			this.fixed_tag = window.scrollY>this.bounding_tag.top;
			this.height_tag = this.fixed_tag ? this.tag_warper.offsetHeight+'px' : 0;
		},
		async load_more_project (){
			this.load_more_project_loading = true
			const all_posts = await this.$axios.$get('wp/v2/posts',{
				params: { wpse_custom_order: 'menu_order', order: 'asc',per_page:100 ,lang:this.$i18n.locale}
			})
			this.$root.context.store.commit('POSTS_UPDATE', all_posts)
			this.$root.context.store.commit('TAGS_UPDATE', TagList.updateTagList(all_posts))
			this.$root.context.store.commit('CATS_UPDATE', CatList.updateCatList(all_posts))
			this.updatePostList()
		},
		updatePostList: function(){
			this.posts = this.$store.state.posts
			this.tags = this.$store.state.tags
			this.currentPostVisibleID = []
			this.activeTag = []
			this.fillVisibleId()
			this.load_more_project_loading = false
			this.load_more_project_btn = false
			this.$root.context.store.commit('LOAD_MORE_PROJECT', false)
		},
		filtertag: function (slug) {
			this.currentPostVisibleID = []
			if(this.activeTag.includes(slug)){
				const index = this.activeTag.indexOf(slug);
				if (index > -1) {
				  this.activeTag.splice(index, 1);
				}
			}else{
				this.activeTag.push(slug)
			}
			this.posts.forEach((post) => {
				if(!this.activeTag.length){////////////// if there is no active tag
					
					if(this.filteredByCat){/////---------------------------- if we filter post list by cat 
						post.cats_infos.slug.forEach((slug) => {/////- for each cat slug from the post we're looking at 
							if(this.filteredByCat==slug){////////////- if it's the current cat 
								this.currentPostVisibleID.push(post.id)
							}
						})
					}else{//////////- -- we had it to the list no question asked
						this.currentPostVisibleID.push(post.id)
					}

				}else{
					this.activeTag.forEach((tag) => {
						if( post.tags_infos.slug.includes(tag) ){
							this.currentPostVisibleID.push(post.id)
						}
					})
				}
			})
			const yOffset = -90; 
			const element = this.$el.querySelector('.post-list');
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
		},
		// filtercat: function (slug) {
		// 	this.currentPostVisibleID = []
		// 	if(this.activeCat.includes(slug)){
		// 		const index = this.activeCat.indexOf(slug);
		// 		if (index > -1) {
		// 		  this.activeCat.splice(index, 1);
		// 		}
		// 	}else{
		// 		this.activeCat.push(slug)
		// 	}
		// 	this.posts.forEach((post) => {
		// 		if(!this.activeCat.length){
		// 			this.currentPostVisibleID.push(post.id)
		// 		}else{
		// 			this.activeCat.forEach((cat) => {
		// 				console.log('cat '+cat);
		// 				console.log('post.cats_infos.slug.includes(cat)'+post.cats_infos.slug.includes(cat));
		// 				if( post.cats_infos.slug.includes(cat) ){
		// 					this.currentPostVisibleID.push(post.id)
		// 				}
		// 			})
		// 		}
		// 	})
		// 	const yOffset = -90; 
		// 	const element = this.$el.querySelector('.post-list');
		// 	const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
		// 	window.scrollTo({top: y, behavior: 'smooth'});
		// }
	},
	head() {
  		if(this.posts[0]){
		    return {
		      link: [{ rel: 'preload', as: 'image', href: this.posts[0].images.medium_large }],
		    }
  		}
	}
  }
